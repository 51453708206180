import CrudService from '../../services/CrudService';

const namespaced = true;

const state = {
  vervangingenHistoryMetadata: [],
  vervangingenHistoryData: {},
  machineNaam: null,
  vervangMomentenIds: []
};

const mutations = {
  SET_VERVANGINGEN_METADATA(state, payload) {
    payload.properties.push({
      variableName: 'printLabel',
      displayName: 'Print',
      pinned: true,
      displayType: 10,
      disabledConditions: [
        {
          propertyName: 'werkelijkeTool',
          notEmpty: true
        }
      ]
    });
    state.vervangingenHistoryMetadata = payload;
  },

  SET_VERVANGINGEN_DATA(state, payload) {
    state.vervangingenHistoryData = payload;
  },

  SET_MACHINE_NAAM(state, payload) {
    state.machineNaam = payload;
  },

  SET_VERVANG_MOMENTEN_IDS(state, payload) {
    state.vervangMomentenIds = payload;
  }
};

const actions = {
  getVervangingenHistoryMetadata({ commit }) {
    CrudService.getMetadataByName('vervangingen', 'grid')
      .then((response) => {
        commit('SET_VERVANGINGEN_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getVervangingenHistoryData({ commit }, vervangMomentenIds) {
    CrudService.getMany('Vervangingen/byvervangmomenten', vervangMomentenIds)
      .then((response) => {
        commit('SET_VERVANGINGEN_DATA', response.data.value);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getMachine({ commit }, machineId) {
    CrudService.getDataById('machines', machineId)
      .then((response) => {
        if (response.data.success) {
          commit('SET_MACHINE_NAAM', response.data.value.naam);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  printLabels({ commit }, vervangingen) {
    CrudService.postData(
      'werkelijketools/printLabels/vervangingen',
      vervangingen.map((x) => x.werkelijkeToolId)
    )
      .then((response) => {
        commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De labels worden geprint.' }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  }
};

const tabletvervangingenhistory = {
  state,
  mutations,
  actions,
  namespaced
};

export default tabletvervangingenhistory;
